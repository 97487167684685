
import { defineComponent } from "vue-demi";
import Subscriber from "@/components/subscriber/List.vue";

export default defineComponent({
  name: "SubscriberView",
  components: {
    Subscriber,
  },
});
